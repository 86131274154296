<template>
    <b-card>
        <div class="my-2">
            <b-row class="mx-2">
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">ID</h4>
                    <h6 class="font-weight-normal">{{parkinglot.id}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Identificador</h4>
                    <h6 class="font-weight-normal">{{parkinglot.identifier}}</h6>
                </b-col>
                <b-col cols="6" md="4" class="my-1">
                    <h4 class="font-weight-bolder">Precio</h4>
                    <h6 class="font-weight-normal">{{parkinglot.price}}</h6>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: 'PropertyDetailGeneral',
        props: ['parkinglot']
    }
</script>
<template>
    <div id="propeties-details">
        <properties-details-list :property="property"></properties-details-list>
    </div>
</template>

<script>
    import PropertiesDetailsList from './components/PropertiesDetailsList'

    export default{
        name: 'PropertiesDetails',
        components: {PropertiesDetailsList},
        props: ['property'],
        data() {
            return {

            }
        },
    }
</script>
<template>
    <div>
        <b-tabs pills vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        nav-class="nav-left"
        nav-wrapper-class="col-md-3 col-12">
            <b-tab active>
                <template #title>
                <feather-icon
                    icon="UserIcon"
                    size="18"
                    class="mr-50"/>
                <span class="font-weight-bold">General</span>
                </template>
                <property-detail-general :general="property"></property-detail-general>
            </b-tab>
            <div v-if="property.complements.length > 0">
                <b-tab v-if="property.complements[0].warehouse != null">
                    <template #title>
                    <feather-icon
                        icon="BoxIcon"
                        size="18"
                        class="mr-50"/>
                    <span class="font-weight-bold">Bodega</span>
                    </template>
                    <property-detail-warehouse :warehouse="property.complements[0].warehouse"></property-detail-warehouse>
                </b-tab>
                <b-tab v-if="property.complements[0].parkinglot != null">
                    <template #title>
                    <feather-icon
                        icon="TruckIcon"
                        size="18"
                        class="mr-50"/>
                    <span class="font-weight-bold">Estacionamiento</span>
                    </template>
                    <property-detail-parkinglot :parkinglot="property.complements[0].parkinglot"></property-detail-parkinglot>
                </b-tab>
            </div>
        </b-tabs>
    </div>
</template>

<script>
  import PropertyDetailGeneral from './details/property/PropertyDetailGeneral'
  import PropertyDetailParkinglot from './details/property/PropertyDetailParkinglot'
  import PropertyDetailWarehouse from './details/property/PropertyDetailWarehouse'

  export default{
    name: 'PropertysDetailsList',
    props: ['property'],
    components:{
      PropertyDetailGeneral,
      PropertyDetailParkinglot,
      PropertyDetailWarehouse
    },
    data() {
      return {
      }
    },
  }
</script>
<template>
  <div>
    <b-card>
      <div class="borderDiv my-5">
        <div>
          <div class="mx-3 my-3">
            <b-row>
              <b-col>
                <div class="align-center flex">
                  <h2 class="font-weight-bolder">
                    <feather-icon icon="HomeIcon" size="30" class="mr-50" />Propiedad
                  </h2>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="my-2">
            <b-row class="mx-2">
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Proyecto" label-for="identifier">
                  <b-form-input id="identifier" :value="general.project.name" :disabled="true" name="identifier" />
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Identificador" label-for="identifier">
                  <b-form-input id="identifier" v-model="editForm.identifier" name="identifier" placeholder="Ej: A1-101"
                    :state="identifierErrors.length > 0 ? false : null" @input="$v.editForm.identifier.$touch()" />
                  <small class="text-danger">
                    {{ identifierErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Modelo" label-for="model">
                  <b-form-input id="model" v-model="editForm.model" name="model" placeholder="Ej: A1"
                    :state="modelErrors.length > 0 ? false : null" @input="$v.editForm.model.$touch()" />
                  <small class="text-danger">
                    {{ modelErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Torre" label-for="tower">
                  <b-form-input id="tower" v-model="editForm.tower" name="tower" placeholder="Ej: A"
                    :state="towerErrors.length > 0 ? false : null" @input="$v.editForm.tower.$touch()" />
                  <small class="text-danger">
                    {{ towerErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Lote" label-for="lots">
                  <b-form-input id="lots" v-model="editForm.lots" name="lots" placeholder="Ej: 107"
                    :state="lotsErrors.length > 0 ? false : null" @input="$v.editForm.lots.$touch()" />
                  <small class="text-danger">
                    {{ lotsErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Tipo de propiedad" label-for="type">
                  <v-select :value="general.project.type_property === 'house'
                      ? 'Casa'
                      : 'Departamento'
                    " label="name" placeholder="Selecciona una propiedad" :options="type" :disabled="true"
                    :reduce="type => type.id">
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Área total" label-for="total_area">
                  <b-form-input id="total_area" v-model="editForm.total_area" name="total_area" placeholder="Ej: 60"
                    :state="totalAreaErrors.length > 0 ? false : null" @input="$v.editForm.total_area.$touch()" />
                  <small class="text-danger">
                    {{ totalAreaErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Área municipal" label-for="municipal_area">
                  <b-form-input id="municipal_area" v-model="editForm.municipal_area" name="municipal_area"
                    placeholder="Ej: 60" :state="municipalAreaErrors.length > 0 ? false : null"
                    @input="$v.editForm.municipal_area.$touch()" />
                  <small class="text-danger">
                    {{ municipalAreaErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Área terraza" label-for="terrace_area">
                  <b-form-input id="terrace_area" v-model="editForm.terrace_area" name="terrace_area"
                    placeholder="Ej: 10" :state="terraceAreaErrors.length > 0 ? false : null"
                    @input="$v.editForm.terrace_area.$touch()" />
                  <small class="text-danger">
                    {{ terraceAreaErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Área construcción" label-for="built_area">
                  <b-form-input id="built_area" v-model="editForm.built_area" name="built_area" placeholder="Ej: 70"
                    :state="builtAreaErrors.length > 0 ? false : null" @input="$v.editForm.built_area.$touch()" />
                  <small class="text-danger">
                    {{ builtAreaErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Piso" label-for="floor">
                  <b-form-input id="floor" v-model="editForm.floor" name="floor" placeholder="Ej: 1"
                    :state="floorErrors.length > 0 ? false : null" @input="$v.editForm.floor.$touch()" />
                  <small class="text-danger">
                    {{ floorErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Dormitorios" label-for="bedrooms">
                  <b-form-input id="bedrooms" v-model="editForm.bedrooms" name="bedrooms" placeholder="Ej: 2"
                    :state="bedroomsErrors.length > 0 ? false : null" @input="$v.editForm.bedrooms.$touch()" />
                  <small class="text-danger">
                    {{ bedroomsErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Baños" label-for="bathrooms">
                  <b-form-input id="bathrooms" v-model="editForm.bathrooms" name="bathrooms" placeholder="Ej: 2"
                    :state="bathroomsErrors.length > 0 ? false : null" @input="$v.editForm.bathrooms.$touch()" />
                  <small class="text-danger">
                    {{ bathroomsErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Vulnerable" label-for="vulnerable">
                  <v-select v-model="editForm.vulnerable" label="name" placeholder="Selecciona" :options="vulnerable"
                    :reduce="vulnerable => vulnerable.id">
                    <span slot="no-options"> No se encontraron opciones </span>
                  </v-select>
                  <small class="text-danger">{{ vulnerableErrors[0] }}</small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Precio" label-for="price">
                  <b-form-input id="price" v-model="editForm.price" name="price" placeholder="Ej: 2200"
                    :state="priceErrors.length > 0 ? false : null" @input="$v.editForm.price.$touch()" />
                  <small class="text-danger">
                    {{ priceErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="ID CRM" label-for="id_crm_movendo">
                  <b-form-input id="id_crm_movendo" v-model="editForm.id_crm_movendo" name="id_crm_movendo"
                    placeholder="Ej: 10" :state="idCrmErrors.length > 0 ? false : null"
                    @input="$v.editForm.id_crm_movendo.$touch()" />
                  <small class="text-danger">
                    {{ idCrmErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Subsidio base" label-for="base_subsidies">
                  <b-form-input id="base_subsidies" v-model="editForm.base_subsidies" name="base_subsidies"
                    placeholder="Ej: 250" :state="baseSubsidiesErrors.length > 0 ? false : null"
                    @input="$v.editForm.base_subsidies.$touch()" />
                  <small class="text-danger">
                    {{ baseSubsidiesErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Ahorro" label-for="savings">
                  <b-form-input id="savings" v-model="editForm.savings" name="savings" placeholder="Ej: 80"
                    :state="savingsErrors.length > 0 ? false : null" @input="$v.editForm.savings.$touch()" />
                  <small class="text-danger">
                    {{ savingsErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Bono integración" label-for="bonus_integration">
                  <b-form-input id="bonus_integration" v-model="editForm.bonus_integration" name="bonus_integration"
                    placeholder="Ej: 10" :state="bonusIntegrationErrors.length > 0 ? false : null"
                    @input="$v.editForm.bonus_integration.$touch()" />
                  <small class="text-danger">
                    {{ bonusIntegrationErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <b-form-group label="Bono captación" label-for="bonus_recruitment">
                  <b-form-input id="bonus_recruitment" v-model="editForm.bonus_recruitment" name="bonus_recruitment"
                    placeholder="Ej: 10" :state="bonusRecruitmentErrors.length > 0 ? false : null"
                    @input="$v.editForm.bonus_recruitment.$touch()" />
                  <small class="text-danger">
                    {{ bonusRecruitmentErrors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mx-2">
              <b-col cols="12">
                <b-button class="float-right" ref="button" :disabled="overlayForm" variant="primary"
                  @click.prevent="_editProperty()">
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle">Guardar</span>
                </b-button>
              </b-col>
            </b-row>
          </div>

          <div>
            <hr />
          </div>
          <div class="mx-3 my-3">
            <b-row>
              <b-col>
                <div class="align-center flex">
                  <h2 class="font-weight-bolder">
                    <feather-icon icon="BellIcon" size="30" class="mr-50" />Estado
                  </h2>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="my-2">
            <b-row class="mx-2">
              <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Visible</h4>
                <b-form-checkbox v-model="general.visible" switch size="lg"></b-form-checkbox>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Se puede reservar</h4>
                <b-form-checkbox v-model="general.bookeable" switch size="lg"></b-form-checkbox>
              </b-col>
              <b-col cols="6" md="4" class="my-1">
                <h4 class="font-weight-bolder">Se puede promesar</h4>
                <b-form-checkbox v-model="general.promisable" switch size="lg"></b-form-checkbox>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required, numeric, decimal } from 'vuelidate/lib/validators'

export default {
  name: 'PropertiesDetailsGeneral',
  props: ['general'],
  components: {},
  mounted() {
    this._setEditForm()
  },
  watch: {
    'general.visible': function (val) {
      this._changePropertyVisible()
    },
    'general.bookeable': function (val) {
      this._changePropertyBookable()
    },
    'general.promisable': function (val) {
      this._changePropertyPromisable()
    }
  },
  methods: {
    ...mapActions('properties', [
      'changePropertyVisible',
      'changePropertyBookable',
      'changePropertyPromisable',
      'changePropertyPrice',
      'editPropertyAll'
    ]),
    numberPrice(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    _onHidden() { },
    async _editProperty() {
      this.$v.editForm.$touch()
      if (!this.$v.editForm.$invalid) {
        this.overlayForm = true
        await this.editPropertyAll(this.editForm)
          .then(res => {
            this.showSuccess()
            this.$router.push({ name: 'properties', params: {} })
          })
          .catch(err => {
            this.showError()
          })
          .finally(() => {
            self.overlayForm = false
          })
      } else {
        this.overlayForm = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campos con errores o sin rellenar',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      }
    },
    async _changePropertyPrice() {
      let self = this
      if (this.general.price == '' || this.general.price == undefined) {
        return false
      } else {
        this.overlayForm = true
        await this.changePropertyPrice(this.general)
          .then(res => {
            this.showSuccess()
            this.$router.push({ name: 'properties', params: {} })
          })
          .catch(err => {
            this.showError()
          })
          .finally(() => {
            self.overlayForm = false
          })
      }
    },
    async _changePropertyVisible() {
      await this.changePropertyVisible(this.general)
        .then(res => {
          this.showSuccess()
        })
        .catch(err => {
          this.showError()
        })
        .finally(() => { })
    },
    async _changePropertyBookable() {
      await this.changePropertyBookable(this.general)
        .then(res => {
          this.showSuccess()
        })
        .catch(err => {
          this.showError()
        })
        .finally(() => { })
    },

    async _changePropertyPromisable() {
      await this.changePropertyPromisable(this.general)
        .then(res => {
          this.showSuccess()
        })
        .catch(err => {
          this.showError()
        })
        .finally(() => { })
    },
    showSuccess: function () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Guardado con exito',
          icon: 'ThumbsUpIcon',
          variant: 'success'
        }
      })
    },
    showError: function () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Existe un error',
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (evt.key == '.') {
        evt.preventDefault()
        return false
        if (this.general.price.split('.').length == 1) {
          return true
        } else {
          evt.preventDefault()
        }
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    _setEditForm() {
      this.editForm.property_id = this.general.id
      this.editForm.identifier = this.general.identifier
      this.editForm.model = this.general.model
      this.editForm.tower = this.general.tower
      this.editForm.lots = this.general.lots
      this.editForm.total_area = this.general.total_area
      this.editForm.municipal_area = this.general.municipal_area
      this.editForm.terrace_area = this.general.terrace_area
      this.editForm.built_area = this.general.built_area
      this.editForm.floor = this.general.floor
      this.editForm.bedrooms = this.general.bedrooms
      this.editForm.bathrooms = this.general.bathrooms
      this.editForm.vulnerable = this.general.vulnerable
      this.editForm.price = this.general.price
      this.editForm.id_crm_movendo = this.general.id_crm_movendo
      this.editForm.base_subsidies = this.general.base_subsidies
      this.editForm.savings = this.general.savings
      this.editForm.bonus_integration = this.general.bonus_integration
      this.editForm.bonus_recruitment = this.general.bonus_recruitment
    }
  },
  data() {
    return {
      overlayForm: false,
      type: [
        { id: 'Casa', name: 'Casa' },
        { id: 'Departamento', name: 'Departamento' }
      ],
      vulnerable: [
        { id: true, name: 'Si' },
        { id: false, name: 'No' }
      ],
      editForm: {
        property_id: '',
        identifier: '',
        model: '',
        tower: '',
        lots: '',
        total_area: '',
        municipal_area: '',
        terrace_area: '',
        built_area: '',
        floor: '',
        bedrooms: '',
        bathrooms: '',
        vulnerable: '',
        price: '',
        id_crm_movendo: '',
        base_subsidies: '',
        savings: '',
        bonus_integration: '',
        bonus_recruitment: '',
      }
    }
  },
  beforeMount() { },
  computed: {
    identifierErrors() {
      const errors = []
      if (!this.$v.editForm.identifier.$dirty) return errors
      !this.$v.editForm.identifier.required &&
        errors.push('El campo es requerido')
      return errors
    },
    modelErrors() {
      const errors = []
      if (!this.$v.editForm.model.$dirty) return errors
      !this.$v.editForm.model.required && errors.push('El campo es requerido')
      return errors
    },
    towerErrors() {
      const errors = []
      if (!this.$v.editForm.tower.$dirty) return errors
      !this.$v.editForm.tower.required && errors.push('El campo es requerido')
      return errors
    },
    lotsErrors() {
      const errors = []
      if (!this.$v.editForm.lots.$dirty) return errors
      !this.$v.editForm.lots.required && errors.push('El campo es requerido')
      return errors
    },
    totalAreaErrors() {
      const errors = []
      if (!this.$v.editForm.total_area.$dirty) return errors
      !this.$v.editForm.total_area.required &&
        errors.push('El campo es requerido')
      !this.$v.editForm.total_area.decimal &&
        errors.push('El debe ser un número')
      return errors
    },
    municipalAreaErrors() {
      const errors = []
      if (!this.$v.editForm.municipal_area.$dirty) return errors
      !this.$v.editForm.municipal_area.required &&
        errors.push('El campo es requerido')
      !this.$v.editForm.municipal_area.decimal &&
        errors.push('El debe ser un número')
      return errors
    },
    terraceAreaErrors() {
      const errors = []
      if (!this.$v.editForm.terrace_area.$dirty) return errors
      !this.$v.editForm.terrace_area.required &&
        errors.push('El campo es requerido')
      !this.$v.editForm.terrace_area.decimal &&
        errors.push('El debe ser un número')
      return errors
    },
    builtAreaErrors() {
      const errors = []
      if (!this.$v.editForm.built_area.$dirty) return errors
      !this.$v.editForm.built_area.required &&
        errors.push('El campo es requerido')
      !this.$v.editForm.built_area.decimal &&
        errors.push('El debe ser un número')
      return errors
    },
    floorErrors() {
      const errors = []
      if (!this.$v.editForm.floor.$dirty) return errors
      !this.$v.editForm.floor.required && errors.push('El campo es requerido')
      !this.$v.editForm.floor.numeric && errors.push('El debe ser un número')
      return errors
    },
    bedroomsErrors() {
      const errors = []
      if (!this.$v.editForm.bedrooms.$dirty) return errors
      !this.$v.editForm.bedrooms.required &&
        errors.push('El campo es requerido')
      !this.$v.editForm.bedrooms.numeric && errors.push('El debe ser un número')
      return errors
    },
    bathroomsErrors() {
      const errors = []
      if (!this.$v.editForm.bathrooms.$dirty) return errors
      !this.$v.editForm.bathrooms.required &&
        errors.push('El campo es requerido')
      return errors
    },
    vulnerableErrors() {
      const errors = []
      if (!this.$v.editForm.vulnerable.$dirty) return errors
      !this.$v.editForm.vulnerable.required &&
        errors.push('El campo es requerido')
      return errors
    },
    priceErrors() {
      const errors = []
      if (!this.$v.editForm.price.$dirty) return errors
      !this.$v.editForm.price.required && errors.push('El campo es requerido')
      !this.$v.editForm.price.decimal &&
        errors.push('El campo debe ser un número')
      return errors
    },
    idCrmErrors() {
      const errors = []
      if (!this.$v.editForm.id_crm_movendo.$dirty) return errors
      !this.$v.editForm.id_crm_movendo.numeric &&
        errors.push('El campo debe ser un número')
      return errors
    },
    baseSubsidiesErrors() {
      const errors = []
      if (!this.$v.editForm.base_subsidies.$dirty) return errors
      !this.$v.editForm.base_subsidies.numeric &&
        errors.push('El campo debe ser un número')
      return errors
    },
    savingsErrors() {
      const errors = []
      if (!this.$v.editForm.savings.$dirty) return errors
      !this.$v.editForm.savings.numeric && errors.push('El campo debe ser un número')
      return errors
    },
    bonusIntegrationErrors() {
      const errors = []
      if (!this.$v.editForm.bonus_integration.$dirty) return errors
      !this.$v.editForm.bonus_integration.numeric &&
        errors.push('El campo debe ser un número')
      return errors
    },
    bonusRecruitmentErrors() {
      const errors = []
      if (!this.$v.editForm.bonus_recruitment.$dirty) return errors
      !this.$v.editForm.bonus_recruitment.numeric &&
        errors.push('El campo debe ser un número')
      return errors
    }
  },
  validations: {
    editForm: {
      identifier: {
        required
      },
      model: {
        required
      },
      tower: {
        required
      },
      lots: {
        required
      },
      total_area: {
        required,
        decimal
      },
      municipal_area: {
        required,
        decimal
      },
      terrace_area: {
        required,
        decimal
      },
      built_area: {
        required,
        decimal
      },
      floor: {
        required,
        numeric
      },
      bedrooms: {
        required,
        numeric
      },
      bathrooms: {
        required
      },
      vulnerable: {
        required
      },
      price: {
        required,
        decimal
      },
      id_crm_movendo: {
        numeric
      },
      base_subsidies: {
        numeric
      },
      savings: {
        numeric
      },
      bonus_integration: {
        numeric
      },
      bonus_recruitment: {
        numeric
      }
    }
  }
}
</script>
